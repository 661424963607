import { LiveChatWidget } from "@livechat/widget-react";
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import BackToTop from "./components/BackToTop";
import Protected from "./components/protected";
import { PhrasesProvider } from "./context/phrasesContext";
import { RequesterProvider } from "./context/requesterContext";
import Authentication from "./pages/Authentication";
import CaseArchives from "./pages/CaseArchives";
import CaseDetails from "./pages/CaseDetails";
import CaseOverView from "./pages/CaseOverview";
import CaseTracking from "./pages/CaseTracking";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Refund from "./pages/Refund";
import Request from "./pages/Request";
import TermsAndConditions from "./pages/TermsAndConditions";
import UploadRecepit from "./pages/UploadRecepit";
import Login from "./pages/login";
import OrderDetails from "./pages/orderDetails";
import Payment from "./pages/payment";
import Profile from "./pages/profile";
import { AuthWrapper } from "./providers/authContext";
import PageNotFound from "./pages/PageNotFound";
import { ErrorBoundary } from "./components/ErrorBoundary";
import PaymentMethodPage from "./pages/PaymentMethodPage";
import PaymentViaBank from "./pages/PaymentViaBank";

const route = [
  { path: "/", element: <ErrorBoundary><Home /> </ErrorBoundary> },
  { path: "/terms", element: <ErrorBoundary><TermsAndConditions /> </ErrorBoundary> },
  { path: "/Privacy", element: <ErrorBoundary><PrivacyPolicy /></ErrorBoundary> },
  {
    path: "/Refund",
    element: (
      <Refund />
    ),
  },
  {
    path: "/UploadReceipt",
    element: (
      <Protected>
        <UploadRecepit />
      </Protected>
    ),
  },
  {
    path: "/case-tracking",
    element: (
      <Protected>
        <CaseTracking />
      </Protected>
    ),
  },
  {
    path: "/case-overview",
    element: (
      <Protected>
        <CaseOverView />
      </Protected>
    ),
  },
  { path: "/case-details/:caseid", element: <CaseDetails /> },
  {
    path: "/order-details/:caseid/:serviceid",
    element: (
      <Protected>
        <OrderDetails />
      </Protected>
    ),
  },
  {
    path: "/archives",
    element: (
      <Protected>
        <CaseArchives />
      </Protected>
    ),
  },
  {
    path: "/request",
    element: (
      <Protected>
        <Request />
      </Protected>
    ),
  },
  {
    path: "/payment",
    element: (
      <Protected>
        <Payment />
      </Protected>
    ),
  },
  {
    path: "/profile",
    element: (
      <Protected>
        <Profile />
      </Protected>
    ),
  },
  { path: "/login", element: <ErrorBoundary><Login /></ErrorBoundary> },
  { path: "/authentication", element: <ErrorBoundary><Authentication /></ErrorBoundary> },
  { path: "*", element: <PageNotFound /> },
  {
    path: "/payment-method/:caseid/:serviceid",
    element: (
      <Protected>
        <PaymentMethodPage />
      </Protected>
    ),
  },
  {
    path: "/Payment-Via-Bank/:caseid/:serviceid",
    element: (
      <Protected>
        <PaymentViaBank />
      </Protected>
    ),
  },
];

export default function App(props) {
  const router = createBrowserRouter(route);



  return (
    <PhrasesProvider value={props}>
      <RequesterProvider value={props}>
        <AuthWrapper>
          <RouterProvider router={router} />
          <noscript>
            <a href="https://www.livechat.com/chat-with/16802304/" rel="nofollow">
              Chat with us
            </a>
            , powered by{" "}
            <a
              href="https://www.livechat.com/?welcome"
              rel="noopener nofollow noreferrer"
              target="_blank"
            >
              LiveChat
            </a>
          </noscript>

          <script src="http://unpkg.com/@livechat/widget-core@1.0.1"></script>
          <script src="https://unpkg.com/@livechat/widget-react@1.0.1"></script>

          <script>
            {`window.__lc = window.__lc || {};
  window.__lc.license = "16802304";
  window.__lc.chat_between_groups = false;
  window.__lc.params = [
    { name: 'language', value: 'ar' }
  ];
  (function() {
    var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
    lc.src = 'https://cdn.livechatinc.com/tracking.js';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
  })();`}
          </script>

          <LiveChatWidget
            group={`${window.localStorage.getItem("lang") === "en" ? "0" : "1"}`}
            license="16802304" visibility="minimized" />

          <BackToTop />
        </AuthWrapper>
      </RequesterProvider>
    </PhrasesProvider>
  );
}
