/* eslint-disable no-lone-blocks */
import React, { useContext, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useNavigate } from "react-router-dom";
import { PhrasesContext } from "../context/phrasesContext";
import { useAuthContext } from "../providers/authContext";
import { getUaePassUserInfo } from "../services/Common";
import Nav from "../layouts/Nav";
import FooterLayout from "../layouts/FooterLayout";
export default function Authentication() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  const queryString = window.location.search;
  const [authenticated, setAuthenticated] = useState(false);
  const urlParams = new URLSearchParams(queryString);
  const { isAuthorized } = useAuthContext();
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  // console.log("isAuthorized", isAuthorized);
  // console.log("urlParams", urlParams);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
    } else {
      document.body.dir = "rtl";
      setLang("ar");
    }
  }, []);
  const [headingText, setHeadingText] = useState({
    head: "",
    subHead: "",
  });
  useEffect(() => {
    // console.log("renderinggggggg");

    if (
      !urlParams.has("error_description") &&
      urlParams.has("code") &&
      !authenticated
    ) {
      setAuthenticated(true);
      getUserData(urlParams.get("code"));
    } else if (urlParams.has("error")) {
      {
        setError(
          urlParams.get("error").includes("invalid_request") ||
            urlParams.get("error").includes("login_required") ||
            urlParams.get("error").includes("access_denied") ||
            urlParams.get("error").includes("cancelledOnApp")
            ? "cancelled"
            : "failed"
        );
      }
    } else if (urlParams.has("status")) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (error) {
      // console.log("errorerrorerror", error);
      setHeadingText({
        head:
          error === "cancelled"
            ? lang === "en"
              ? enPhrase["lblLoginCancelled"]
              : arPhrase["lblLoginCancelled"]
            : error === "SOP1"
            ? lang === "en"
              ? enPhrase["lblUAELoginFailedTxt"]
              : arPhrase["lblUAELoginFailedTxt"]
            : error === "blocked"
            ? lang === "en"
              ? enPhrase["lblAccountBlocked"]
              : arPhrase["lblAccountBlocked"]
            : error === "failed"
            ? lang === "en"
              ? enPhrase["lblSomethingWentWrongLogin"]
              : arPhrase["lblSomethingWentWrongLogin"]
            : null,
        subHead:
          error === "cancelled"
            ? lang === "en"
              ? enPhrase["lblPleaseTryToLoginAgain"]
              : arPhrase["lblPleaseTryToLoginAgain"]
            : error === "SOP1"
            ? lang === "en"
              ? enPhrase["lblUAELoginFailedTxt"]
              : arPhrase["lblUAELoginFailedTxt"]
            : error === "blocked"
            ? lang === "en"
              ? enPhrase["lblAccountBlockedTxt"]
              : arPhrase["lblAccountBlockedTxt"]
            : error === "failed"
            ? lang === "en"
              ? enPhrase["lblSomethingWentWrongLogin"]
              : arPhrase["lblSomethingWentWrongLogin"]
            : null,
      });
    }
  }, [error]);

  const getUserData = async (code) => {
    setLoading(true);
    getUaePassUserInfo(code, lang)
      .then(async (res) => {
        // console.log("res", res);
        if (res.token) {
          localStorage.setItem("access_token", res.token);
          navigate("/");
          window.location.reload();
        } else if (res.title === "User Not Verefied" || res.code === 150) {
          setError("SOP1");
        } else {
          setError("failed");
        }
        // console.log("ErrorAPI", error);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        // console.log("ERROR", error);
        setError("failed");
      });
  };

  const changeLanguage2 = (lang) => {
    if (lang === "en") {
      document.body.dir = "rtl";
      setLang("ar");
      window.localStorage.setItem("lang", "ar");
    } else {
      document.body.dir = "ltr";
      setLang("en");
      window.localStorage.setItem("lang", "en");
    }
    window.location.reload();
  };

  // const getUri = () => {
  //   return (
  //     "https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id=ajm_eje_web_stage&scope=urn:uae:digitalid:profile:general&state=CVvGgPTpRmEkjZBX&redirect_uri=https://auth.eje.ae&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=" +
  //     lang
  //   );
  // };

  const getUri = () => {
    return (
      "https://id.uaepass.ae/idshub/logout?redirect_uri=https://eje.ae/login&ui_locales=" +
      lang
    );
  };

  return (
    <>
      <Nav
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        changeLanguage2={changeLanguage2}
        hideUpload={true}
        isUAEPass={true}
      />

      <div className="min-h-[764px] xl:min-h-[728px] m-auto flex items-center justify-center">
        {loading && (
          <div
            className={
              " flex items-center justify-center flex-col w-[360px] p-[32px] bg-[#fff] border-solid border-[1px] border-[#ffd4d4] rounded-[24px] shadow-[0_25px_50px_0_rgba(54,30,18,0.15)]"
            }
          >
            <SkeletonTheme>
              <Skeleton
                count={5}
                containerClassName="flex flex-col w-full"
              ></Skeleton>
            </SkeletonTheme>
          </div>
        )}

        {!loading && (!authenticated || error) ? (
          <div
            className={
              "flex items-center justify-center flex-col w-[360px] p-[32px] bg-[#fff] border-solid border-[1px] border-[#ffd4d4] rounded-[24px] shadow-[0_25px_50px_0_rgba(54,30,18,0.15)]"
            }
          >
            {!authenticated || error ? (
              <>
                <h3 className="text-[18px] text-[#000] font-Almarai text-center">
                  {headingText?.head}
                  {/* {lang === "en"
                    ? enPhrase["lblUAELoginFailedTxt"]
                    : arPhrase["lblUAELoginFailedTxt"]} */}
                  {/* You are not eligible to access this service. Your account is either not upgraded, or you have a visitor account */}
                  {/* الهوية الرقمية الخاصة بك غير موثقة ، من فضلك قم بتوثيق الهوية الرقمية الخاصة بك لإمكانية التسجيل لدينا */}
                </h3>
              </>
            ) : null}

            {!loading && (!authenticated || error) ? (
              <div className="w-[296px] mt-[32px]">
                <Link
                  className="w-full flex items-center justify-center h-[56px] bg-[#b68a35] rounded-[8px] py-[20px] px-[32px]"
                  to={
                    "https://id.uaepass.ae/idshub/logout?redirect_uri=http://eje.ae/&ui_locales=" +
                    lang
                  }
                  // to={
                  //   "https://stg-id.uaepass.ae/idshub/logout?redirect_uri=http://eje.arabiansystems.com/&ui_locales=" +
                  //   lang
                  // }
                >
                  <span className="text-[14px] text-[#fff] font-Almarai font-bold leading-[2.86]">
                    {lang === "en" ? "OK" : "حسناً"}
                  </span>
                </Link>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <FooterLayout lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} />
    </>
  );
}
