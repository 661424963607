import React from "react";
import NewsMobile from "../components/NewsMobile";

const News = ({ lang, enPhrase, arPhrase, media }) => {
  return (
    <div id="news">
      {window && window.innerWidth < 1024 ? (
        <NewsMobile NewsData={media} lang={lang} />
      ) : (
        <div className="w-full flex flex-row items-center justify-center mb-[80px] mt-[144px] gap-[24px] px-[24px]">
          <div
            className="w-[312px] h-[445px] border-[1px] border-solid border-[#e6d7a2] rounded-[24px] bg-[#f9f7ed] px-[32px] pt-[32px]"
            style={{
              backgroundImage: "url(/assets/img/home/bgNews.svg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom",
            }}
          >
            <p className="text-[40px] font-Almarai font-bold max-w-[248px]">
              {lang === "en" ? "Media Center" : "المركز الإعلامي"}
            </p>
          </div>
          {media?.map((item, index) => {
            return (
              <div
                key={index}
                className="w-[312px] h-[445px] border-[1px] border-solid border-[#e6d7a2] rounded-[24px] bg-[#f9f7ed]"
              >
                <div className="flex items-center justify-center">
                  <img
                    // src={item?.image.replace("https://cdn.eje.ae/media/", "")}
                    src={
                      item?.image.includes("https://www.emiratesauction.com")
                        ? item?.image.replace("https://cdn.eje.ae/media/", "")
                        : item?.image
                    }
                    alt={item?.title}
                    className="h-[232.5px] flex items-center justify-center rounded-t-[24px]"
                  />
                </div>
                <div className="px-[24px] pt-[24px] ">
                  <h2 className="text-[19px] font-Almarai font-bold text-[#361e12] mb-[16px] max-h-[66px] overflow-hidden text-ellipsis whitespace-pre-line">
                    {item?.title}
                  </h2>
                  <p className="text-[16px] font-Almarai text-[#361e12] max-h-[72px] overflow-hidden text-ellipsis whitespace-pre-line">
                    {item?.descreption}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default News;
