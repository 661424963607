import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../components/breadCrumb";
import ProfileItem from "../components/profile/profile-item";
import { PhrasesContext } from "../context/phrasesContext";
import ThemeLayout from "../layouts/ThemeLayout";
import { useAuthContext } from "../providers/authContext";

import { RequesterContext } from "../context/requesterContext";

const Profile = (props) => {
  // const { caseid, serviceid } = useParams();
  const { HappinessHandlerClick } = useAuthContext();
  // const [requester, setRequester] = useState([]);
  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});
  // const [service, setService] = useState({});
  // const [openPopub, setOpenPopub] = useState();

  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [requesterInfo] = useContext(RequesterContext);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");

    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
    } else {
      document.body.dir = "rtl";
      setLang("ar");
    }
    // getPhrasesService();
    // getRequesterService(currentLang);
  }, []);

  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return "";
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };

  // const getRequesterService = (currentLang) => {
  //   return getRequester(currentLang).then((res) => {
  //     setRequester(res);
  //   });
  // };

  return (
    <ThemeLayout>
      <div className="flex flex-col w-full py-4 max-w-[1140px] px-[24px] lg:px-0 ">
        <Breadcrumb
          title={
            lang === "en" ? enPhrase["lblMycases"] : arPhrase["lblMycases"]
          }
          last={
            lang === "en" ? enPhrase["lblMyProfile"] : arPhrase["lblMyProfile"]
          }
        />
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="w-full md:w-[70%]">
            <div className="w-full">
              <div className="flex flex-col items-center justify-center border-2 border-solid border-[#EEE2D0] rounded-4 shadow-[0px_10px_40px_#361E1214] overflow-hidden">
                <div className="flex justify-between items-center w-full p-6 border-b-2 border-solid border-[#EEE2D0] bg-white">
                  <p className="font-bold text-[20px] font-Almarai text-[#010202]">
                    {" "}
                    {lang === "en"
                      ? enPhrase["lblMyProfile"]
                      : arPhrase["lblMyProfile"]}{" "}
                  </p>
                  <img
                    src="/assets/img/user-menu/uae-pass.png"
                    alt="logout"
                    className="w-24 h-7"
                  />
                </div>
                <div className="px-4 py-4 w-full bg-white flex flex-row flex-wrap gap-y-4 content-between justify-between">
                  <div className="flex flex-col content-between justify-between min-w-[49%]">
                    <p className="text-[12px] font-normal text-[#010202]">
                      {lang === "en" ? "Name" : "الاسم"}
                    </p>
                    <p className="text-base font-bold text-[#010202]">
                      {requesterInfo?.name}
                    </p>
                  </div>
                  <div className="flex flex-col content-between justify-between min-w-[49%]">
                    <p className="text-[12px] font-normal text-[#010202]">
                      {lang === "en"
                        ? enPhrase["lblEmail"]
                        : arPhrase["lblEmail"]}
                    </p>
                    <p className="text-base font-bold text-[#010202]">
                      {requesterInfo?.email}
                    </p>
                  </div>
                  <div className="flex flex-col content-between justify-between min-w-[49%]">
                    <p className="text-[12px] font-normal text-[#010202]">
                      {lang === "en" ? "Mobile Number" : "الهاتف"}
                    </p>
                    <p
                      className={`text-base font-bold  text-[#010202] ${
                        lang === "en" ? "text-start" : "text-end"
                      }`}
                      dir="ltr"
                    >
                      {requesterInfo?.mobile}
                    </p>
                  </div>
                  <div className="flex flex-col content-between justify-between min-w-[49%]">
                    <p className="text-[12px] font-normal text-[#010202]">
                      {lang === "en"
                        ? enPhrase["lblIDNumber"]
                        : arPhrase["lblIDNumber"]}
                    </p>
                    <p className="text-base font-bold text-[#010202]">
                      {/* 784-1979-1234567-1 */}
                      {requesterInfo?.emiratesIdNo}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-3 w-full md:w-[30%]">
            <ProfileItem
              text={
                lang === "en"
                  ? enPhrase["lblCaseArchive"]
                  : arPhrase["lblCaseArchive"]
              }
              icon="/assets/img/user-menu/archive.svg"
              href="/archives"
              arrow="/assets/img/button/arrow.svg"
              lang={lang}
            />
            <ProfileItem
              text={
                lang === "en"
                  ? enPhrase["lblHappiness"]
                  : arPhrase["lblHappiness"]
              }
              icon="/assets/img/user-menu/face-satisfied.svg"
              href="/profile"
              arrow="/assets/img/button/arrow.svg"
              click={HappinessHandlerClick}
              lang={lang}
            />
            <div className="w-[96%] h-[1px] bg-[#EEE2D0]"></div>
            <ProfileItem
              text={
                lang === "en" ? enPhrase["lblSignout"] : arPhrase["lblSignout"]
              }
              href={
                "https://id.uaepass.ae/idshub/logout?redirect_uri=http://eje.ae/login&ui_locales=" +
                lang
              }
              // href={
              //   "https://stg-id.uaepass.ae/idshub/logout?redirect_uri=https://eje.arabiansystems.com/login&ui_locales=" +
              //   lang
              // }
              arrow="/assets/img/user-menu/logout.svg"
              click={() => {
                localStorage.removeItem("access_token");
                sessionStorage.removeItem("access_token");
                window.location.href = "/";
              }}
              lang={lang}
            />
          </div>
        </div>
      </div>
    </ThemeLayout>
  );
};

export default Profile;
