import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./index.css";

const NewsMobile = (props) => {
  const { NewsData, lang } = props;
  const [sliderKey, setSliderKey] = useState(0);
 
  useEffect(() => {
    // Force re-render after component is mounted
    setSliderKey(1);
  }, []);
  // const settings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   initialSlide: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1.35,
  //         slidesToScroll: 1,
  //         infinite: false,
  //         dots: false,
  //         initialSlide: 1,
  //       },
  //     },
  //   ],
  // };
  // const settings = {
  //   className: "center",
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1.5,
  //   slidesToScroll: 1,
  //   centerPadding: "60px",

  // };
  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: false,
    centerPadding: "24px",
    slidesToShow: 1.1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          key: sliderKey,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 912,
        settings: {
          key: sliderKey,
          slidesToShow: 2.8,
          centerPadding: "45px",
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 820,
        settings: {
          key: sliderKey,
          slidesToShow: 2,
          centerPadding: "10px",
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 768,
        settings: {
          key: sliderKey,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "35px",
          infinite: false,
          dots: true,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          key: sliderKey,
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 540,
        settings: {
          key: sliderKey,
          slidesToShow: 1.8,
          slidesToScroll: 1,
          centerPadding: "40px",
          infinite: false,
          dots: true,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 430,
        settings: {
          key: sliderKey,
          slidesToShow: 1.4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerPadding: "20px",
          initialSlide: 1,
          speed: 200,
        },
      },

      {
        breakpoint: 414,
        settings: {
          key: sliderKey,
          slidesToShow: 1.3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerPadding: "0px",
          initialSlide: 1,
          speed: 150,
        },
      },

      {
        breakpoint: 390,
        settings: {
          key: sliderKey,
          slidesToShow: 1.24,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerPadding: "20px",
          initialSlide: 1,
          speed: 150,
          lazyLoad: "ondemand",
        },
      },

      {
        breakpoint: 375,
        settings: {
          key: sliderKey,
          slidesToShow: 1.2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerPadding: "20px",
          initialSlide: 1,
          speed: 150,
          // slidesToShow: 1.1,
          // slidesToScroll: 1,
          // dots: false,
          // infinite:true
        },
      },
      {
        breakpoint: 360,
        settings: {
          key: sliderKey,
          slidesToShow: 1.15,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerPadding: "20px",
          initialSlide: 1,
          speed: 150,
          // slidesToShow: 1.1,
          // slidesToScroll: 1,
          // dots: false,
          // infinite:true
        },
      },
    ],
  };
  return (
    <div className="mb-[80px] px-[24px]">
      <div
        className="w-full h-[119px] p-[24px]  bg-[#f9f7ed] m-auto rounded-[20px] !mb-[16px]"
        style={{
          backgroundImage: "url(/assets/img/home/bgNews.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
          backgroundSize: "contain",
        }}
      >
        <p className="w-[140px] h-[71px] text-[28px] text-[#361e12] font-Almarai font-bold leading-snug">
          {lang === "en" ? "Media Center" : "المركز الإعلامي"}
        </p>
      </div>

      <div className="h-[414px] m-auto w-full">
        <Slider {...settings}>
          {NewsData?.map((item, index) => {
            return (
              <div
                key={index}
                className="!w-[263px] h-[414px] bg-[#fff] border-[1px] border-[#e6d7a2] border-solid rounded-[24px] mb-[20px]"
              >
                <div className="flex items-center justify-center">
                  <img
                    // src={item?.image.replace("https://cdn.eje.ae/media/", "")}
                    src={
                      item?.image.includes("https://www.emiratesauction.com")
                        ? item?.image.replace("https://cdn.eje.ae/media/", "")
                        : item?.image
                    }
                    alt={item?.title}
                    className=" h-[190px] flex items-center justify-center rounded-t-[24px]"
                  />
                </div>

                <div dir={`${lang === "ar" ? "rtl" : "ltr"}`}>
                  <p className="text-[18px] text-[#361e12] mx-[24px] mt-[24px] mb-[16px] font-Almarai font-bold w-[215px] leading-normal text-center max-h-[108px] overflow-hidden text-ellipsis whitespace-pre-line	">
                    {item?.title}
                  </p>
                  <p className="text-[13px] text-[#361e12] mx-[24px] font-Almarai mb-[24px] leading-[1.43] text-center max-h-[66px] overflow-hidden text-ellipsis whitespace-pre-line">
                    {item?.descreption}
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default NewsMobile;
